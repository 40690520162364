
import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import WeatherImg from '../../images/weather.svg'
import MultipurposeImg from '../../images/multipurpose.svg'
import InstallationImg from '../../images/installation.svg'
import AffordableImg from '../../images/affordable.svg'
import DurableImg from '../../images/durable.svg'
import fireproofImg from '../../images/fire-proof.svg'


const BuyMetalGarage = (props) => {  
  return (       
    <Row>
      <Col lg={4} md={6}>
        <Card className='shadow-card'>
          <div className='card-inner'>
          <Card.Img variant="top" src={WeatherImg} alt='All-Weather Protection'/>
            <Card.Body>
            <Card.Title className='title-underline'>All-Weather Protection</Card.Title>
              <Card.Text>Steel buildings provide superior protection from rain, snow, wind, debris, lightning, and are also better designed for enduring earthquake activity.</Card.Text>
            </Card.Body>
            </div>
          </Card>
      </Col>
      <Col lg={4} md={6}>
        <Card className='shadow-card'>
        <div className='card-inner'>
          <Card.Img variant="top" src={MultipurposeImg} alt='Multipurpose Use'/>
            <Card.Body>
            <Card.Title className='title-underline'>Multipurpose Use</Card.Title>
              <Card.Text>Metal buildings offer more flexibility of design than their wooden counterparts. Steel structures are also easier to adapt or expand as your needs change.</Card.Text>
            </Card.Body>
            </div>
          </Card>
      </Col>
      <Col lg={4} md={6}>
        <Card className='shadow-card'>
        <div className='card-inner'>
          <Card.Img variant="top" src={InstallationImg} alt='Delivery & Installation Included '/>
            <Card.Body>
            <Card.Title className='title-underline'>Delivery & Installation Included </Card.Title>
              <Card.Text>Not only is the price right, but you also get a little something extra with a prefab metal building – delivery and installation is included with purchase!</Card.Text>
            </Card.Body>
            </div>
          </Card>
      </Col>
      <Col lg={4} md={6}>
        <Card className='shadow-card'>
        <div className='card-inner'>
          <Card.Img variant="top" src={AffordableImg} alt='The Price Is Right'/>
            <Card.Body>
            <Card.Title className='title-underline'>The Price Is Right </Card.Title>
              <Card.Text>Assume that steel is more expensive than wood? Not necessarily! It’s actually more cost-effective, and features a very competitive price per square foot.</Card.Text>
            </Card.Body>
            </div>
          </Card>
      </Col>
      <Col lg={4} md={6}>
        <Card className='shadow-card'>
        <div className='card-inner'>
          <Card.Img variant="top" src={DurableImg} alt='Strong and Durable'/>
            <Card.Body>
            <Card.Title className='title-underline'>Strong and Durable</Card.Title>
              <Card.Text>Steel buildings simply won’t rot like wood. Plus, they stand the tests of time and use much better than wood or other materials.</Card.Text>
            </Card.Body>
            </div>
          </Card>
      </Col>
      <Col lg={4} md={6}>
        <Card className='shadow-card'>
        <div className='card-inner'>
          <Card.Img variant="top" src={fireproofImg} alt='Pest & Fire Resistant'/>
            <Card.Body>
            <Card.Title className='title-underline'>Pest & Fire Resistant </Card.Title>
              <Card.Text>Termites and other pests are a constant threat to wood, as are mold and mildew. Not so with steel. What about fire? Steel won’t burn!</Card.Text>
            </Card.Body>
            </div>
          </Card>
      </Col>
    </Row>
  )
}
export default BuyMetalGarage; 