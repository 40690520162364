import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Slider from "react-slick"
import StickyBox from "react-sticky-box"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button, Breadcrumb } from "react-bootstrap"
import { Section, MarkdownContent } from "../components/Section"
import PatternImg from "../images/line-pattern.png"
import MoreInformation from "../components/MoreInformation"
import TabData from "../components/TabsContent/Tabdatalist"
import CirclesProdcut from "../images/circles-prodcut.svg"
import { ArrowLeftIcon, ArrowrRightIcon } from "../components/Icons"
import BuyMetalGarage from "../components/BuyMetalGarage"
import Ripples from "react-ripples"
import RequestForm from "../components/RequestForm"
import WhatNeedKnow from "../components/WhatNeedKnow"
import Image from "../components/image"

const BtnWrapper = styled.div`
  padding: 0;
  .react-ripples {
    margin: 0px 10px 10px;
    .btn {
      padding: 6px 20px;
      tetx-align: center;
    }
  }
`
function SampleNextArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

function SamplePrevArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

class MetalCarports extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.Slider.slickNext()
  }
  previous() {
    this.Slider.slickPrev()
  }

  render() {
    var settings = {
      dots: false,
      arrows: false,
      loop: false,
      infinite: true,
      speed: 500,
      centerMode: false,
      slidesToShow: 2,
      centerPadding: "10px",
      variableWidth: true,
      slidesToScroll: 1,
      NextArrow: <SampleNextArrow />,
      PrevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            centerPadding: "0px",
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "20px",
            dots: false,
          },
        },
      ],
    }
    const pageData = this.props.data.markdownRemark
    const { location } = this.props;
    const formId = "Request a Call";
    return (
      <Layout location = { location }>
        <SEO
          title={pageData.frontmatter.metaTitle}
          description={pageData.frontmatter.metaDesc}
        />
        <Container className="breadcrumbs-position">
          <Breadcrumb className="beadcrumbs-outer white">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>
              {pageData.frontmatter.mainCateogry}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Container>
        <Section className="section most-versitile-solution" pt="200px">
          <img
            src={CirclesProdcut}
            alt="..."
            className="product-circle-image"
          />
          <Container>
            <Row className="metal-garage-para" data-aos="fade-up">
              <Col lg={7} xl={6}>
                <div className="section-heading">
                  <span className="h2 text-secondary section-title">
                    {pageData.frontmatter.mainCateogry}
                  </span>
                  <h1 className="h2 section-title">
                    {pageData.frontmatter.mainHeading}
                  </h1>
                </div>
              </Col>
              <Col xl={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 0 }}>
                <div className="section-content">
                  <p>{pageData.frontmatter.bannerSideDesc}</p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row data-aos="fade-up" data-aos-delay="100">
              <Col lg={4} xl={3}>
                <div className="section-content">
                  <p>{pageData.frontmatter.mainCategoryDesc}</p>
                </div>
              </Col>
              <Col lg={{ span: 8 }} xl={{ span: 9 }} className="pr-sm-0">
                <Slider {...settings} ref={c => (this.Slider = c)}>
                  {pageData.frontmatter.mainCategorySilderImages.map(image => {
                    return (
                      <div className="slider-item">
                        <Image name={image} alt={image} />
                      </div>
                    )
                  })}
                </Slider>
                <BtnWrapper>
                  <Ripples className="border-radius-27">
                    <Button
                      variant="secondary arrow-prev"
                      onClick={this.previous}
                    >
                      <ArrowLeftIcon stroke="#fff" width="22px" height="14px" />
                    </Button>
                  </Ripples>
                  <Ripples className="border-radius-27">
                    <Button variant="secondary arrow-next" onClick={this.next}>
                      <ArrowrRightIcon
                        stroke="#fff"
                        Arro
                        width="22px"
                        height="14px"
                      />
                    </Button>
                  </Ripples>
                </BtnWrapper>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section
          className="section tennesse-steel-buidling line-pattern"
          pt="80px"
          bgBefore={`url(${PatternImg})`}
        >
          <Container>
            <div className="section-heading">
              <span className="section-subtitle">
                {pageData.frontmatter.mainCateogry}
              </span>
              <h2 className="section-title">
                {pageData.frontmatter.subCategarySectionHeading}
              </h2>
              <Row>
                <Col lg={8}>
                  <p>{pageData.frontmatter.subCategorySectionDesc}</p>
                </Col>
              </Row>
            </div>
            <div className="section-content">
              {pageData.frontmatter.subCategoriesTabData.map(subCategory => {
                return (
                  <TabData
                    tabsSubtitle={subCategory.tabsSubtitle}
                    tabTitle={subCategory.tabTitle}
                    tabDescription={subCategory.tabDescription}
                    tabImage={subCategory.tabImage}
                    tabLink={subCategory.tabLink}
                  />
                )
              })}
            </div>
          </Container>
        </Section>
        <MoreInformation />
        {pageData.frontmatter.roofStyleHeading && 
          <WhatNeedKnow
            roofStyleSubHeading={pageData.frontmatter.roofStyleSubHeading}
            roofStyleHeading={pageData.frontmatter.roofStyleHeading}
            roofStyleDesc={pageData.frontmatter.roofStyleDesc}
            data={pageData.frontmatter.roofStyleData}
          />       
        }
        <Section
          className="section by-metal-garage line-pattern"
          pt="90px"
          pb="90px"
          bgBefore={`url(${PatternImg})`}
        >
          <Container>
            <Row>
              <Col lg={7}>
                <div className="section-heading">
                  <span className="section-subheading">
                    {pageData.frontmatter.whySubHeading}
                  </span>
                  <h2 className="section-title">
                    {pageData.frontmatter.whyMainHeading}
                  </h2>
                  <p>{pageData.frontmatter.whyDesc}</p>
                </div>
              </Col>
            </Row>
            <BuyMetalGarage />
          </Container>
        </Section>
        <Section
          className="section"
          pb="50px"
          pt="50px"
          bg={`url(${PatternImg})`}
        >
          <Container>
            <Row>
              <Col lg={7}>
                <MarkdownContent
                  dangerouslySetInnerHTML={{ __html: pageData.html }}
                />
              </Col>
              <Col xl={{ span: 4, offset: 1 }} lg={5}>
                <StickyBox offsetTop={170}>
                  <div className="form-wrapper">
                    <h2 className="form-title">Request a Call</h2>
                    <RequestForm location = { location } formId = { formId } />
                  </div>
                </StickyBox>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default MetalCarports

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        metaTitle
        metaDesc
        mainCateogry
        mainHeading
        bannerSideDesc
        mainCategoryDesc
        mainCategorySilderImages
        subCategarySectionHeading
        subCategorySectionDesc
        subCategoriesTabData {
          tabDescription
          tabImage
          tabTitle
          tabsSubtitle
          tabLink
        }
        roofStyleSubHeading
        roofStyleHeading
        roofStyleDesc
        roofStyleData {
          roofDesc
          roofHeading
          roofSubHeading
        }
        whySubHeading
        whyMainHeading
        whyDesc
      }
      html
    }
  }
`
